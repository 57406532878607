import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "../components/dashboardLayout/DashboardLayout"; // Import the new layout
import LandingPage from "./LandingPage/LandingPage";
import SearchPage from "./SearchPage/SearchPage";
import SearchDetailPage from "./SearchPage/SearchDetailPage";
import VehicleDetailsPage from "./SearchPage/VehicleDetailsPage";
import SelectPurposePage from "./AuthPage/SelectPurposePage";
import LoginPage from "./AuthPage/LoginPage";
import AccountSetupPage from "./AuthPage/AccountSetupPage";
import EmailVerificationForm from "./AuthPage/EmailVerificationForm";
import HostVehiclePage from "./HostVehiclePage/HostVehiclePage";
import DashboardPage from "./DashboardPage/DashboardPage";
import RegisterPage from "./AuthPage/RegisterPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import ListedVehiclePage from "./DashboardPage/ListedVehiclePage";
import ReportPage from "./DashboardPage/ReportPage";
import WithdrawalPage from "./DashboardPage/WithdrawalPage";
import InspectionPage from "./DashboardPage/InspectionPage";
import RemittancePage from "./DashboardPage/RemittancePage";
import ContactUsPage from "./LandingPage/ContactUsPage";
import HowItWorksPage from "./LandingPage/HowItWorksPage";
import AboutUsPage from "./LandingPage/AboutUsPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/how-it-works",
    element: <HowItWorksPage />,
  },
  {
    path: "/about",
    element: <AboutUsPage />,
  },
  {
    path: "/contact",
    element: <ContactUsPage />,
  },
  {
    path: "/search",
    element: <SearchPage />,
  },
  {
    path: "/search/result",
    element: <SearchDetailPage />,
  },
  {
    path: "/search/:carID",
    element: <VehicleDetailsPage />,
  },
  {
    path: "/auth/purpose",
    element: <SelectPurposePage />,
  },
  {
    path: "/auth/login",
    element: <LoginPage />,
  },
  {
    path: "/auth/register",
    element: <RegisterPage />,
  },
  {
    path: "/auth/otp",
    element: <EmailVerificationForm />,
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />, // Use DashboardLayout as the parent
    children: [
      {
        path: "",
        element: <DashboardPage />, // Default dashboard page
      },
      {
        path: "bikes",
        element: <ListedVehiclePage />, // Default dashboard page
      },
      {
        path: "report",
        element: <ReportPage />, // Default dashboard page
      },
      {
        path: "withdrawal",
        element: <WithdrawalPage />, // Default dashboard page
      },
      {
        path: "inspection",
        element: <InspectionPage />, // Default dashboard page
      },
      {
        path: "host-vehicle",
        element: <HostVehiclePage />,
      },
      {
        path: "vehicle/:carID",
        element: <VehicleDetailsPage />,
      },
      {
        path: "setup-account",
        element: <AccountSetupPage />,
      },
      {
        path: "payment",
        element: <RemittancePage />,
      },
      {
        path: "profile",
        element: <ProfilePage />,
      },
      
    ],
  },
]);
