import React from "react";
import { LandingPageNavigation } from "../../components/navigation/NavigationComponent";
import "./LandingPage.scss";
import { FAQComponent, FooterComponent } from "./LandingPage";

function ContactUsPage() {
  return (
    <div>
      <section className="w-full  z-[99999] mb-5 fixed top-0 bg-white">
        <LandingPageNavigation />
      </section>

      <section className="back-ruge-bg-image  w-full flex  justify-center mt-[70px]">
        <div className="flex justify-evenly gap-10 items-center w-[1440px]">
          <div className="hidden md:block image-container w-[731px] h-[806px]">
            <img
              className="size-full object-cover "
              // src="https://via.placeholder.com/731x806"
              src={require("../../assets/image/contactus.jpg")}
            />
          </div>

          <div className="my-10 md:my-0 px-10 md:pe-3 md:p-0 w-full md:max-w-[559px] min-h-[616px] flex-col justify-start items-start gap-[45px] inline-flex">
            <div className="h-[104px] flex-col justify-start items-start flex mb-5">
              <div className="self-stretch text-white text-[45px] font-bold  leading-[72px]">
                Get in touch
              </div>
              <div className="text-white/60 text-xl font-semibold  leading-tight">
                Have an enquiry? Fill out the form to contact our team
              </div>
            </div>
            <div className=" h- [374px] w-full flex-col justify-start items-start gap-5 flex">
              <div className="flex-col md:flex-row w-full justify-start items-center gap-[31px] inline-flex">
                <input
                  type="text"
                  placeholder="Full Name"
                  className="w-full md:max-w-[264px] h-[62px] px-7 py-[13px] rounded-[20px] border-2 bg-transparent outline-none text-white/50 text-xl border-white"
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="w-full md:max-w-[264px] h-[62px] px-7 py-[13px] rounded-[20px] border-2 bg-transparent outline-none text-white/50 text-xl border-white"
                />
              </div>
              <div className=" flex-col md:flex-row w-full justify-start items-center gap-[31px] inline-flex">
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="w-full md:max-w-[264px] h-[62px] px-7 py-[13px] rounded-[20px] border-2 bg-transparent outline-none text-white/50 text-xl border-white"
                />
                <input
                  type="text"
                  placeholder="Email address"
                  className="w-full md:max-w-[264px] h-[62px] px-7 py-[13px] rounded-[20px] border-2 bg-transparent outline-none text-white/50 text-xl border-white"
                />
              </div>
              <textarea
                className=" h-[210px] w-full px-7 py-[13px] rounded-[20px] border-2 border-white justify-start bg-transparent outline-none inline-flex text-white/50 text-xl"
                placeholder="Message"
              ></textarea>
            </div>
            <div className="px-[50px] py-2.5 bg-white rounded-[50px] justify-center items-center gap-2.5 inline-flex">
              <div className="text-[#110e0d] text-2xl font-bold ">Submit</div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full flex justify-center items-center">
        <div className="py-20 pb-10 flex-col justify-center items-center gap-[50px] inline-flex">
          <div className="flex-col justify-center items-center gap-2 flex">
            <div className=" text-center text-black text-[45px] font-bold  leading-[58.50px]">
              Get in touch
            </div>
            <div className=" text-center text-black/40 text-xl font-bold  leading-relaxed">
              We always send you a quick response form to contact our team
            </div>
          </div>


          <div className="flex flex-col gap-5 justify-even ly flex-wr ap   ">
            <div className="flex flex-row gap-5 justify-evenly flex-wrap md:flex-nowrap   ">

              {/*  */}
              <div className="flex  flex-col md:flex-row w-full md:max-w-[280px] items-center gap-5  bg-[#ececec] px-5 py-5 rounded-[15px] mx-5 ">
                <div className="w-[63px] h-[63px] px-[21.91px] py-[19.17px] bg-[#f46702] rounded-[38.35px] justify-center items-center gap-[13.70px] inline-flex">
                  <div className="w-[32.87px] h-[32.87px] relative" >
                    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.3916 4.67383C12.6372 4.67376 12.8782 4.7397 13.0895 4.86475C13.3008 4.9898 13.4746 5.16936 13.5928 5.38463L13.6626 5.53528L16.4017 12.3831C16.5121 12.6586 16.5299 12.9625 16.4526 13.249C16.3753 13.5355 16.207 13.7892 15.9731 13.9718L15.8361 14.0649L13.5434 15.44L13.6297 15.581C14.7209 17.3135 16.1867 18.7793 17.9192 19.8705L18.0589 19.9554L19.4353 17.6655C19.5879 17.4109 19.819 17.2127 20.0939 17.1008C20.3688 16.9889 20.6726 16.9694 20.9596 17.0451L21.1171 17.0985L27.965 19.8377C28.193 19.9286 28.3924 20.0792 28.5422 20.2736C28.6921 20.4681 28.7869 20.6992 28.8168 20.9429L28.8264 21.1086V26.5869C28.8264 28.8562 26.9871 30.6956 24.6356 30.6928C12.8861 29.9793 3.5196 20.6128 2.80469 8.78252C2.80463 7.73452 3.20505 6.7261 3.92401 5.9636C4.64298 5.2011 5.62614 4.74216 6.67234 4.68068L6.91338 4.67383H12.3916Z" fill="white" />
                    </svg>

                  </div>
                </div>
                <div className="text-center md:text-start">
                  <div className="text-black text-xl font-bold  leading-relaxed">
                    Phone
                  </div>
                  <div className=" text-black/40 text-base font-bold  leading-3">
                    +234 810 197 7171
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="flex flex-col md:flex-row w-full md:max-w-[300px] items-center gap-5   bg-[#ececec] px-5 py-5 rounded-[15px] mx-5">
                <div className="w-[63px] h-[63px] px-[21.91px] py-[19.17px] bg-[#f46702] rounded-[38.35px] justify-center items-center gap-[13.70px] inline-flex">
                  <div className="w-[32.87px] h-[32.87px] relative" >
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M31.7915 24.3952V9.60389C31.7915 8.2398 30.6904 7.13867 29.3263 7.13867H6.30115C4.93707 7.13867 3.83594 8.2398 3.83594 9.60389V24.3952C3.83594 25.7593 4.93707 26.8604 6.30115 26.8604H29.3263C30.6904 26.8604 31.7915 25.7593 31.7915 24.3952ZM29.6385 9.42311C30.1809 9.96545 29.8851 10.5242 29.5892 10.8036L22.9167 16.9174L29.3263 23.5899C29.5235 23.82 29.655 24.1815 29.4249 24.4281C29.2112 24.691 28.7182 24.6746 28.5045 24.5102L21.3225 18.3801L17.8055 21.5848L14.3049 18.3801L7.12289 24.5102C6.90924 24.6746 6.4162 24.691 6.20255 24.4281C5.97246 24.1815 6.10394 23.82 6.30115 23.5899L12.7107 16.9174L6.0382 10.8036C5.74237 10.5242 5.44655 9.96545 5.98889 9.42311C6.53124 8.88076 7.09002 9.14372 7.5502 9.53815L17.8055 17.8213L28.0772 9.53815C28.5374 9.14372 29.0962 8.88076 29.6385 9.42311Z" fill="white" />
                    </svg>

                  </div>
                </div>
                <div className="text-center md:text-start">
                  <div className="text-black text-xl font-bold  leading-relaxed">
                    Email
                  </div>

                  <div className="w-[178px] text-black/40 text-base font-bold  leading-3">
                    hello@binnox.app
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
          <div className="flex  flex-col md:flex-row items-center gap-10 bg-[#ececec] px-5 rounded-[15px] py-5 mx-5">
            <div className="w-[63px] h-[63px] px-[21.91px] py-[19.17px] bg-[#f46702] rounded-[38.35px] justify-center items-center gap-[13.70px] inline-flex">
              <div className="w-[32.87px] h-[32.87px] relative">
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.5019 1.43359C19.7709 1.43359 22.9061 2.73223 25.2177 5.04382C27.5293 7.35541 28.828 10.4906 28.828 13.7597C28.828 18.8627 24.9384 24.2095 17.3236 29.9205C17.0865 30.0983 16.7982 30.1945 16.5019 30.1945C16.2055 30.1945 15.9172 30.0983 15.6801 29.9205C8.06535 24.2095 4.17578 18.8627 4.17578 13.7597C4.17578 10.4906 5.47442 7.35541 7.78601 5.04382C10.0976 2.73223 13.2328 1.43359 16.5019 1.43359ZM16.5019 9.65098C15.4122 9.65098 14.3671 10.0839 13.5966 10.8544C12.8261 11.6249 12.3932 12.67 12.3932 13.7597C12.3932 14.8494 12.8261 15.8944 13.5966 16.665C14.3671 17.4355 15.4122 17.8684 16.5019 17.8684C17.5916 17.8684 18.6366 17.4355 19.4072 16.665C20.1777 15.8944 20.6106 14.8494 20.6106 13.7597C20.6106 12.67 20.1777 11.6249 19.4072 10.8544C18.6366 10.0839 17.5916 9.65098 16.5019 9.65098Z" fill="white" />
                </svg>

              </div>
            </div>
            <div className="text-center md:text-start">
              <div className="text-black text-xl font-bold  leading-relaxed">
                Locations
              </div>

              <ul className="w-[ 178px]  text-black/40 text-base font-bold  leading-6 li st-disc flex flex-col gap-2">
                <li>
                  16192 Coastal Highway, Lewes, Delaware (USA)

                </li>
                <li>
                  No184, Ademola Adetokunbo Crescent, Wuse II, Abuja.

                </li>
                <li>
                  No. 8 Odewusi Street, Post Office Bus-stop, Ipaja, Lagos.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="relative w-full flex justify-center">
        <FAQComponent />
      </section> */}

      <FooterComponent />
    </div>
  );
}

export default ContactUsPage;
