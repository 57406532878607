import React, { useEffect, useState } from "react";
import NavigationComponent from "../../components/navigation/NavigationComponent";
import { TickBadIconSvg, TickGoodIconSvg } from "../../icons";
import { useLocation, useHistory, useNavigate } from "react-router-dom";
import { useDataContext } from "../../Context/dataContext";
import { useAuthContext } from "../../Context/authContext";
import { toast } from "react-toastify";
import { Spinner } from "@chakra-ui/react";
import axios from "axios";
import { InputField } from "../../components/FormElementComponent/FormElementComponent";

function AccountSetupPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentStep, setCurrentStep] = useState(0);
  let action = queryParams.get("action");
  const [progressSteps, setProgressSteps] = useState([]);
  // const progressSteps = [

  // ];
  const { handleProfileUpdateFunction } = useAuthContext();

  useEffect(() => {
    if (!action) {
      navigate("/auth/purpose"); // Replace the current URL with a new one
    }

    if (action === "owner") {
      return setProgressSteps([
        {
          step: 1,
          label: "Personal information",
          content: <PersonalInformationForm action={nextStepFunction} />,
        },
        {
          step: 2,
          label: "National identity",
          content: <OwnerNationalIdentityForm action={nextStepFunction} />,
        },
        {
          step: 3,
          label: "Choose protection plan",
          content: (
            <ChooseProtectionPlan action={() => navigate("/dashboard")} />
          ),
        },
      ]);
    }

    if (action === "rider") {
      return setProgressSteps([
        {
          step: 1,
          label: "Personal information",
          content: <PersonalInformationForm action={nextStepFunction} />,
        },
        {
          step: 2,
          label: "National identity",
          content: <UserNationalIdentityForm action={nextStepFunction} />,
        },
        {
          step: 3,
          label: "Add guarantor",
          content: (
            <UserAddGuarantorForm action={() => navigate("/dashboard")} />
          ),
        },
      ]);
    }
  }, [action]);

  function nextStepFunction(stepIndex) {
    setCurrentStep(stepIndex);
  }
  return (
    <div className="flex overflow-hidden flex-col pb-32 bg-white max-md:pb-24">
      <div className="fixed bg-white top-0 w-full">
        {" "}
        <NavigationComponent />
      </div>
      <main className=" flex flex-wrap gap-10 items-center self-center py-16 mt-32 rounded-2xl bg-neutral-50 max-md:px-5 max-w-[1085px] w-full border ">
        {(currentStep >= 2 && action == "owner") || (
          // (action == "owner" &&
          <section className="flex flex-col justify-center self-stretch m-auto tracking-tight min-w-[240px] text-neutral-900 w-[285px] ">
            <div className="flex flex-col justify-center max-w-full w-[285px]">
              <h1 className="self-start text-3xl font-bold leading-none text-center">
                Setup account
              </h1>
              <p className="mt-5 text-xl leading-6 font-[450]">
                You are this step away from renting your vehicles.
              </p>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a5710d2ec2ed3f38fc2022b717095b702277942a20b9a8005a395a5527934d3?apiKey=e85d4038ac5a41748b51322169f61f43&&apiKey=e85d4038ac5a41748b51322169f61f43"
              alt="Setup account illustration"
              className="object-contain mt-32 max-w-full aspect-[0.78] w-[200px] max-md:mt-10"
            />
          </section>
        )}
        <section className="flex flex-col items-center  font-[450] mx-auto w-full">
          {/* <div className="flex w-full max-w-[480px]">
            {progressSteps.map((step, index) => (
              <React.Fragment key={step.step}>
                <ProgressStep
                  {...step}
                  action={nextStepFunction}
                  currentStep={currentStep}
                />
                {index < progressSteps.length - 1 && (
                  <div
                    className={`shrink-0 self-start mt-5 h-[3px] w-[120px] max-md:mr-0 ${
                      currentStep - 1 >= index
                        ? "bg-neutral-900"
                        : " bg-[#888888B2]"
                    } `}
                  />
                )}
              </React.Fragment>
            ))}
          </div> */}
          {progressSteps[currentStep]?.content}
        </section>
      </main>
    </div>
  );
}

export default AccountSetupPage;

function ProgressStep({ step, label, action, currentStep }) {
  const baseClasses =
    "overflow-hidden flex justify-center w-9 h-9 text-base tracking-tight leading-loose whitespace-nowrap rounded-[113px] border border-[rgb(0, 0, 0)]";
  const activeClasses =
    currentStep + 1 >= step
      ? "text-white bg-neutral-900"
      : "text-neutral-900 text-opacity-70 bg-zinc-100";

  return (
    <div
      className={`flex flex-col justify-start items-center cursor-pointer ${
        currentStep + 1 >= step ? "font-medium" : ""
      }`}
      onClick={() => action(step - 1)}
    >
      <div className={`${baseClasses} ${activeClasses}`}>{step}</div>
      <div
        className={`mt-3 text-sm tracking-tight leading-4 text-center ${
          currentStep + 1 >= step
            ? "text-neutral-900"
            : "text-neutral-900 text-opacity-70"
        }`}
      >
        {label}
      </div>
    </div>
  );
}

export function UploadInputField({ placeholder, accept = "*" }) {
  return (
    <label
      htmlFor={placeholder.toLowerCase().replace(/\s+/g, "-")}
      className="flex gap-2 justify-center items-center py-8 mt-5 w-full font-medium tracking-tight leading-7 rounded-lg text-neutral-900 max-md:px-5 border border-dashed border-[#7c7c7c] cursor-pointer"
    >
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a9917c3fe2a46b9166750538f2436cc08df722b464f7dc9c96556276d830dd1?apiKey=e85d4038ac5a41748b51322169f61f43&&apiKey=e85d4038ac5a41748b51322169f61f43"
        alt=""
        className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
      />
      {placeholder}
      <input
        type="file"
        id={placeholder.toLowerCase().replace(/\s+/g, "-")}
        className="sr-only"
        accept={accept} // Use the accept prop to limit file types or allow all
      />
    </label>
  );
}

export function PersonalInformationForm({ action }) {
  const { profile, handleProfileUpdateFunction } = useAuthContext();

  const [formData, setFormData] = useState({});

  const handelInputChanges = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    setFormData(profile ? profile : {});
  }, [profile]);

  return (
    <div className="flex flex-col gap-[20px] mt-10 w-full text-base tracking-tight leading-none  text-neutral-900">
      {/* TODO: TEst if all this works */}
      <div className="flex gap-[30px] flex-col md:flex-row">
        <InputField
          label={"Account type"}
          type={"text"}
          name={"account_type"}
          handelInputChanges={handelInputChanges}
          stateData={formData}
          disabled={true}
        />{" "}
        <InputField
          label={"Full name"}
          type={"text"}
          name={"full_name"}
          handelInputChanges={handelInputChanges}
          stateData={formData}
        />
      </div>
      <div className="flex gap-[30px] flex-col md:flex-row">
        <InputField
          type={"email"}
          label={"email"}
          name={"email"}
          handelInputChanges={handelInputChanges}
          stateData={formData}
        />
        <InputField
          label={"Phone Number"}
          type={"number"}
          name={"phone_number"}
          handelInputChanges={handelInputChanges}
          stateData={formData}
        />
      </div>{" "}
      <div className="flex gap-[30px] flex-col md:flex-row">
        <InputField
          label={"Gender"}
          type={"text"}
          name={"gender"}
          handelInputChanges={handelInputChanges}
          stateData={formData}
        />{" "}
        <InputField
          label={"Occupation"}
          type={"text"}
          name={"occupation"}
          handelInputChanges={handelInputChanges}
          stateData={formData}
        />
      </div>
      {/* <div className="flex gap-[30px]">
        <InputField
          label={"Full name"}
          type={"text"}
          name={"full_name"}
          handelInputChanges={handelInputChanges}
          stateData={formData}
        />
        <InputField
          type={"email"}
          label={"email"}
          name={"email"}
          handelInputChanges={handelInputChanges}
          stateData={formData}
        />
      </div> */}
      <button
        className="w-[476px] h-[52px] rounded-[100px] bg-[#000] text-[#fff] mt-[27px] mx-auto"
        onClick={() => {
          handleProfileUpdateFunction(formData);
          action(1);
        }}
      >
        Update Profile
      </button>
    </div>
  );
}

export function UploadImageField({ placeholder, onFileChange }) {
  const [progress, setProgress] = useState(0); // State to track upload progress
  const [uploading, setUploading] = useState(false); // State to track if uploading

  const handleChange = async (event) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file && onFileChange) {
      setUploading(true); // Set uploading state
      setProgress(0); // Reset progress
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "ostkv0vd"); // Replace with your Cloudinary upload preset

        // Upload to Cloudinary
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/diki2xwvj/image/upload`, // Replace with your Cloudinary cloud name
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percentage = Math.floor((loaded * 100) / total);
              setProgress(percentage); // Update progress
            },
          }
        );

        onFileChange(response.data.secure_url); // Call the callback with the Cloudinary image URL
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setUploading(false); // Reset uploading state
      }
    }
  };

  return (
    <div className="flex flex-col mt-5">
      <label
        htmlFor={placeholder.toLowerCase().replace(/\s+/g, "-")}
        className="flex gap-2 justify-center items-center py-8 w-full font-medium tracking-tight leading-7 rounded-lg text-neutral-900 border border-dashed border-[#7c7c7c] cursor-pointer"
      >
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a9917c3fe2a46b9166750538f2436cc08df722b464f7dc9c96556276d830dd1?apiKey=e85d4038ac5a41748b51322169f61f43&&apiKey=e85d4038ac5a41748b51322169f61f43"
          alt=""
          className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
        />
        {placeholder}
        <input
          type="file"
          id={placeholder.toLowerCase().replace(/\s+/g, "-")}
          className="sr-only"
          accept="image/*" // Limit to image files
          onChange={handleChange} // Handle file change
        />
      </label>
      {uploading && progress > 0 && (
        <div className="relative w-full mt-2 h-2 bg-gray-200 rounded">
          <div
            className="absolute h-full bg-orange-600 rounded"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </div>
  );
}

export function OwnerNationalIdentityForm({ action }) {
  const [files, setFiles] = useState({}); // State to store the uploaded files

  const handleFileChange = (placeholder, fileUrl) => {
    setFiles((prev) => ({ ...prev, [placeholder]: fileUrl })); // Update state with the new file URL
  };

  return (
    <form className="flex flex-col mt-16 w-full text-base tracking-tight leading-none max-w-[420px] text-neutral-900 text-opacity-70 max-md:mt-10">
      <div className="w-[362px] h-7">
        <p className="[font-family:'Circular_Std-Medium',Helvetica] font-medium text-[#121212] text-xl text-center tracking-[-0.43px] leading-7 whitespace-nowrap">
          Submit one or more of these documents
        </p>
      </div>
      {/* NOTE: this is the uploads for owner ids */}
      <UploadImageField
        placeholder={"NIN card/slip"}
        onFileChange={(fileUrl) => handleFileChange("NIN card/slip", fileUrl)}
      />
      <UploadImageField
        placeholder={"Voters card"}
        accept=".jpg,.jpeg,.png"
        onFileChange={(fileUrl) => handleFileChange("Voters card", fileUrl)}
      />
      <UploadImageField
        placeholder={"International passport"}
        accept=".jpg,.jpeg,.png"
        onFileChange={(fileUrl) =>
          handleFileChange("International passport", fileUrl)
        }
      />
      <UploadImageField
        placeholder={"Drivers license"}
        accept=".jpg,.jpeg,.png"
        onFileChange={(fileUrl) => handleFileChange("Drivers license", fileUrl)}
      />
      <UploadImageField
        placeholder={"Other Documents"}
        onFileChange={(fileUrl) => handleFileChange("Other Documents", fileUrl)}
      />{" "}
      {/* This will accept all files */}
      {/*TODO: Handle submit here */}
      <button
        type="button"
        className="gap-2.5 self-stretch px-2.5 py-5 mt-16 max-w-full text-xl font-medium tracking-tight leading-none text-center text-white whitespace-nowrap bg-orange-600 rounded-lg min-h-[60px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)] w-[420px] max-md:mt-10"
        onClick={() => action(2)}
      >
        Submit
      </button>
    </form>
  );
}

export function UserNationalIdentityForm({ action }) {
  const { profile, handleProfileUpdateFunction } = useAuthContext();
  const [formData, setFormData] = useState({});

  const handelChanges = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  return (
    <form className="flex flex-col mt-16 w-full text-base tracking-tight leading-none max-w-[420px] text-neutral-900 text-opacity-70 max-md:mt-10">
      <div className="">
        <div className="w-[362px] h-5">
          <p className="[font-family:'Circular_Std-Medium',Helvetica] font-medium text-[#121212] text-xl text-start tracking-[-0.43px] leading-7 whitespace-nowrap">
            Compulsory
          </p>
        </div>
        <UploadInputField placeholder={"Drivers license"} />
      </div>
      <div className="mt-[60px]">
        <div className="w-[362px] h-5">
          <p className="[font-family:'Circular_Std-Medium',Helvetica] font-medium text-[#121212] text-xl text-start tracking-[-0.43px] leading-7 whitespace-nowrap">
            Submit one ore more of these documents
          </p>
        </div>
      </div>

      <UploadInputField placeholder={"NIN card/slip"} />
      <UploadInputField placeholder={"Voters card"} />
      <UploadInputField placeholder={"International passport"} />

      <button
        type="button"
        className="gap-2.5 self-stretch px-2.5 py-5 mt-16 max-w-full text-xl font-medium tracking-tight leading-none text-center text-white whitespace-nowrap bg-orange-600 rounded-lg min-h-[60px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)] w-[420px] max-md:mt-10"
        onClick={() => {
          handleProfileUpdateFunction({
            verification_documents: [
              ...profile.verification_documents,
              {
                document_url:
                  "https://fastly.picsum.photos/id/622/200/300.jpg?hmac=HR8-4uUEihkyJx4VczHLFhVvELy7KCD1jm16BABaDy8",
              },
            ],
          });
          action(2);
        }}
      >
        Submit
      </button>
    </form>
  );
}

export function ChooseProtectionPlan({ action }) {
  const { profile, handleProfileUpdateFunction } = useAuthContext();
  const { ProtectionPlans, getProtectionPlansFunction } = useDataContext();

  useEffect(() => {
    getProtectionPlansFunction();
  }, []);

  // const plans = [
  //   {
  //     maintenance_charge: 0,
  //     plans_detail: "You will not pay for your vehicle maintenance",
  //     plans_benefits: [
  //       {
  //         available: true,
  //         benefit: "Gain 60% of vehicle revenue",
  //       },
  //       {
  //         available: true,
  //         benefit: "Up to ₦700k in third party liability insurance",
  //       },
  //       {
  //         available: true,
  //         benefit: "Vooom pays all damage costs",
  //       },
  //       {
  //         available: true,
  //         benefit: "CEO will soon input this Pron.",
  //       },
  //       {
  //         available: true,
  //         benefit: "Include exterior damages reimbursement",
  //       },
  //       {
  //         available: true,
  //         benefit: "Include loss of host income during repair",
  //       },
  //     ],
  //   },
  //   {
  //     maintenance_charge: 20,
  //     plans_detail: "Pay for 20% of your vehicle maintenance",
  //     plans_benefits: [
  //       {
  //         available: true,
  //         benefit: "Gain 70% of vehicle revenue.",
  //       },
  //       {
  //         available: true,
  //         benefit: "Gain 85% of vehicle revenue.",
  //       },
  //       {
  //         available: true,
  //         benefit: "Up to ₦500k in third party liability insurance.",
  //       },
  //       {
  //         available: true,
  //         benefit: "Up to ₦300k in third party liability insurance.",
  //       },
  //       {
  //         available: false,
  //         benefit: "Vooom pays 80% of damage costs.",
  //       },
  //       {
  //         available: false,
  //         benefit: "Vooom pays 50% of damage costs.",
  //       },
  //     ],
  //   },
  //   {
  //     maintenance_charge: 50,
  //     plans_detail: "Pay for 20% of your vehicle maintenance",
  //     plans_benefits: [
  //       {
  //         available: true,
  //         benefit: "CEO will soon input this Pron.",
  //       },
  //       {
  //         available: true,
  //         benefit: "CEO will soon input this Pron.",
  //       },
  //       {
  //         available: true,
  //         benefit: "Doesn’t include exterior damages reimbursement.",
  //       },
  //       {
  //         available: true,
  //         benefit: "Doesn’t include exterior damages reimbursement.",
  //       },
  //       {
  //         available: false,
  //         benefit: "Doesn’t include loss of host income during repair.",
  //       },
  //       {
  //         available: false,
  //         benefit: "Doesn’t include loss of host income during repair.",
  //       },
  //     ],
  //   },
  // ];
  return (
    <div className="flex flex-col flex-wrap w-full items-start gap-8 mt-10">
      <p className=" w-fit mt-[-1.00px] [font-family:'Circular_Std-Medium',Helvetica] font-medium text-[#121212] text-xl text-center tracking-[-0.43px] leading-7 whitespace-nowrap">
        Select a protection plan for your vehicles
      </p>
      <div className="flex items-center justify-center  flex-wrap gap-10  self-stretch w-full flex-[0_0_auto]">
        {ProtectionPlans.loading ? (
          <Spinner />
        ) : (
          <>
            {ProtectionPlans.data.map((plan, index) => {
              return (
                <div
                  key={`ProtectionPlans${index}`}
                  className={`flex flex-col items-start gap-10 p-8  flex-[0_0_auto] ml-[-0.50px] rounded-[11.32px] overflow-hidden border-2 border-solid ${
                    profile?.protection_plan_subscription === plan._id
                      ? "border-orange-600"
                      : "border-[#121212]"
                  } `}
                >
                  <div className="inline-flex flex-col items-start justify-center gap-[16.97px]  flex-[0_0_auto]">
                    <p className=" w-fit mt-[-1.41px] [font-family:'Circular_Std-Regular',Helvetica] font-normal text-[#121212] text-4xl tracking-[-0.58px] leading-10 whitespace-nowrap">
                      <span className="tracking-[-0.21px]">
                        {plan.maintenance_charge}%
                      </span>
                      <span className="text-[33.9px] tracking-[-0.20px]">
                        &nbsp;
                      </span>
                      <span className="[font-family:'Circular_Std-Bold',Helvetica] font-bold text-base tracking-[-0.09px]">
                        maintenance charges
                      </span>
                    </p>
                    <p className=" w-[238px] [font-family:'Circular_Std-Bold',Helvetica] font-bold text-[#121212] text-xl tracking-[-0.58px] leading-[22px]">
                      {plan.plans_detail}
                    </p>
                  </div>
                  <hr />
                  <div className="flex flex-col flex-wrap items-center gap-[32px_32px]  self-stretch w-full">
                    {plan.plans_benefits.map((benefit, index) => {
                      return (
                        <div
                          className="inline-flex items-start gap-[5.66px]  flex-[0_0_auto]"
                          key={`plans_benefits${index}`}
                        >
                          {benefit.available ? (
                            <TickGoodIconSvg />
                          ) : (
                            <TickBadIconSvg />
                          )}
                          <p className=" w-[186.7px] mt-[-1.41px] [font-family:'Circular_Std-Book',Helvetica] font-normal text-[#121212] text-base tracking-[-0.58px] leading-[19.8px]">
                            {benefit.benefit}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    onClick={() => {
                      if (
                        window.confirm(
                          `Select ${plan.maintenance_charge}% maintenance charges`
                        )
                      ) {
                        if (profile?.protection_plan_subscription) {
                          action();
                          return toast.error(
                            "Protection Plan Cant be Updated, Contact Please Admin"
                          );
                        }
                        handleProfileUpdateFunction({
                          protection_plan_subscription: plan._id,
                        });
                      }
                      action();
                    }}
                    className="flex w-[238px] h-12 items-center justify-center gap-2.5 p-2.5 text-white  bg-[#121212] rounded-lg border-[0.5px] border-solid border-[#00000099] shadow-[2px_2px_7.5px_#00000033] cursor-pointer"
                  >
                    {/* <div
                      className=" w-fit [font-family:'Circular_Std-Medium',Helvetica] font-medium text-xl text-center tracking-[-0.43px] leading-[22px] whitespace-nowrap"
                      // onClick={() =>}
                    > */}
                    {profile?.protection_plan_subscription
                      ? "Protection Plane already set"
                      : "Select"}
                    {/* </div> */}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
export function UserAddGuarantorForm({ action }) {
  const { profile, handleProfileUpdateFunction } = useAuthContext();
  // const [formData, setFormData] = useState({});
  const guarantor1Fields = [
    {
      placeholder: "Full name",
      type: "text",
      name: "full_name",
      value: `${profile?.guarantor_documents[0]?.full_name || ""}`,
    },
    {
      placeholder: "Gender",
      type: "text",
      name: "gender",
      value: `${profile?.guarantor_documents[0]?.gender || ""}`,
    },
    {
      placeholder: "Phone number",
      type: "number",
      name: "phone_number",
      value: `${profile?.guarantor_documents[0]?.phone_number || ""}`,
    },
    {
      placeholder: "Email address",
      type: "email",
      name: "email",
      value: `${profile?.guarantor_documents[0]?.email || ""}`,
    },
    {
      placeholder: "Organization name",
      type: "text",
      name: "organization",
      value: `${profile?.guarantor_documents[0]?.organization || ""}`,
    },
    {
      placeholder: "Office location",
      type: "text",
      name: "location",
      value: `${profile?.guarantor_documents[0]?.location || ""}`,
    },
  ];
  const guarantor2Fields = [
    {
      placeholder: "Full name",
      type: "text",
      name: "full_name",
      value: `${profile?.guarantor_documents[1]?.full_name || ""}`,
    },
    {
      placeholder: "Gender",
      type: "text",
      name: "gender",
      value: `${profile?.guarantor_documents[1]?.gender || ""}`,
    },
    {
      placeholder: "Phone number",
      type: "number",
      name: "phone_number",
      value: `${profile?.guarantor_documents[1]?.phone_number || ""}`,
    },
    {
      placeholder: "Email address",
      type: "email",
      name: "email",
      value: `${profile?.guarantor_documents[1]?.email || ""}`,
    },
    {
      placeholder: "Organization name",
      type: "text",
      name: "organization",
      value: `${profile?.guarantor_documents[1]?.organization || ""}`,
    },
    {
      placeholder: "Office location",
      type: "text",
      name: "location",
      value: `${profile?.guarantor_documents[1]?.location || ""}`,
    },
  ];

  // const handelChanges = (e) => {
  //   setFormData((prev) => {
  //     return {
  //       ...prev,
  //       [e.target.name]: e.target.value,
  //     };
  //   });
  // };

  // Function to validate guarantor inputs
  const validateGuarantor = (guarantor) => {
    const requiredFields = [
      "full_name",
      "gender",
      "phone_number",
      "email",
      "organization",
      "location",
    ];

    for (const field of requiredFields) {
      if (!guarantor[field].trim()) {
        return false; // Return false if any field is empty
      }
    }

    return true; // Return true if all fields are filled
  };

  function handelFormSubmit(e) {
    e.preventDefault();
    const formElement = e.target;
    // console.log({ formElement });

    const guarantor1 = {
      full_name: formElement[0].value,
      gender: formElement[1].value,
      phone_number: formElement[2].value,
      email: formElement[3].value,
      organization: formElement[4].value,
      location: formElement[5].value,
    };
    const guarantor2 = {
      full_name: formElement[8].value,
      gender: formElement[9].value,
      phone_number: formElement[10].value,
      email: formElement[11].value,
      organization: formElement[12].value,
      location: formElement[13].value,
    };

    let guarantorDocumentArray = [...profile.guarantor_documents];
    // Validate guarantor1 and guarantor2 inputs
    if (!validateGuarantor(guarantor1) || !validateGuarantor(guarantor2)) {
      toast.error("Please fill in all guarantor fields.");
    } else {
      // Proceed with updating guarantor documents if all fields are filled
      // ... (rest of your code)
      if (guarantorDocumentArray.length === 0) {
        guarantorDocumentArray.push(guarantor1);
        guarantorDocumentArray.push(guarantor2);
      } else {
        guarantorDocumentArray = [guarantor1, guarantor2];
      }
    }

    handleProfileUpdateFunction({
      guarantor_documents: guarantorDocumentArray,
    });
    action(2);
  }
  return (
    <form
      className="flex flex-col mt-16 w-full text-base tracking-tight leading-none max-w-[420px] text-neutral-900 text-opacity-70 max-md:mt-10"
      onSubmit={(e) => handelFormSubmit(e)}
    >
      <div className="">
        <div className="w-[362px] h-5">
          <p className="[font-family:'Circular_Std-Medium',Helvetica] font-medium text-[#121212] text-xl text-start tracking-[-0.43px] leading-7 whitespace-nowrap">
            Government official
          </p>
        </div>
        {guarantor1Fields.map((field, index) => (
          <div
            className="w-full rounded min-h-[72px] max-md:px-5 mt-5 border border-[#121212] overflow-hidden"
            key={index}
          >
            <input
              type={field.type}
              id={field.placeholder.toLowerCase().replace(/\s+/g, "-")}
              placeholder={field.placeholder}
              name={field.name}
              className="size-full bg-white ps-5  min-h-[72px] rounded"
              required
              defaultValue={field.value}

              // onChange={(e) => handelChanges(e)}
            />
          </div>
        ))}

        <UploadInputField placeholder={"Work ID card"} />
        <UploadInputField placeholder={"National ID "} />
      </div>
      <div className="mt-[60px]">
        <div className="w-[362px] h-5">
          <p className="[font-family:'Circular_Std-Medium',Helvetica] font-medium text-[#121212] text-xl text-start tracking-[-0.43px] leading-7 whitespace-nowrap">
            Business person
          </p>
        </div>
      </div>
      {/* <InputField placeholder={"Full name"} type={"text"} />
      <InputField placeholder={"Gender"} type={"text"} />
      <InputField placeholder={"Phone number"} type={"number"} />
      <InputField placeholder={"Email address"} type={"text"} />
      <InputField placeholder={"Organization name"} type={"text"} />
      <InputField placeholder={"Office location"} type={"text"} /> */}
      {guarantor2Fields.map((field, index) => (
        <div
          className="w-full rounded min-h-[72px] max-md:px-5 mt-5 border border-[#121212] overflow-hidden"
          key={index}
        >
          <input
            type={field.type}
            id={field.placeholder.toLowerCase().replace(/\s+/g, "-")}
            placeholder={field.placeholder}
            name={field.name}
            defaultValue={field.value}
            className="size-full bg-white ps-5  min-h-[72px] rounded"
            required
            // onChange={(e) => handelChanges(e)}
          />
        </div>
      ))}
      <button
        type="submit"
        className="gap-2.5 self-stretch px-2.5 py-5 mt-16 max-w-full text-xl font-medium tracking-tight leading-none text-center text-white whitespace-nowrap bg-orange-600 rounded-lg min-h-[60px] shadow-[2px_2px_8px_rgba(0,0,0,0.2)] w-[420px] max-md:mt-10"
        // onClick={() => {

        // }}
      >
        Submit
      </button>
    </form>
  );
}
